import Stage1 from './images/stage-1.webp';
import Stage2 from './images/stage-2.webp';
import Stage3 from './images/stage-3.webp';
import Stage4 from './images/stage-4.webp';
import Stage5 from './images/stage-5.webp';
import { CaretCircleRightIcon } from '../../../../components/Icons/icons';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useState } from 'react';
import 'swiper/css';
import s from './ImportantStages.module.scss';
import { useMediaQuery } from 'usehooks-ts';
import { animation } from '../../../../utils/animation';

const ImportantStages = () => {
  const [swiperInstance, setSwiperInstance] = useState<any>();
  const isMobile = useMediaQuery('(max-width: 768px)');

  return (
    <div className={s.container}>
      <animation.h2>5 najważniejszych etapów</animation.h2>

      <div className={s.stagesWrapper}>
        <Swiper
          onSwiper={(swiper) => setSwiperInstance(swiper)}
          spaceBetween={30}
          className={s.stages}
          slidesPerView={isMobile ? 1 : 2}
        >
          <SwiperSlide>
            <div className={s.stage}>
              <animation.img reverse={true} src={Stage1} alt='stage' />

              <div className={s.caption}>
                <animation.p className='body-1'>
                  Przeniesienie dzieci do świata bohaterów oraz ich wyzwań
                </animation.p>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className={s.stage}>
              <animation.img src={Stage2} alt='stage' />

              <div className={s.caption}>
                <animation.p className='body-1'>
                  Instrukcja obsługi aplikacji
                </animation.p>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className={s.stage}>
              <animation.img reverse={true} src={Stage3} alt='stage' />

              <div className={s.caption}>
                <animation.p className='body-1'>
                  Samodzielna praca dzieci
                </animation.p>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className={s.stage}>
              <animation.img src={Stage4} alt='stage' />

              <div className={s.caption}>
                <animation.p className='body-1'>
                  Przedstawienie efektów pracy
                </animation.p>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className={s.stage}>
              <animation.img reverse={true} src={Stage5} alt='stage' />

              <div className={s.caption}>
                <animation.p className='body-1'>Quiz po lekcji</animation.p>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>

        <div className={s.navigation}>
          <CaretCircleRightIcon
            onClick={() => swiperInstance?.slidePrev()}
            width='35px'
            height='35px'
            fill='#000'
            style={{ transform: 'rotate(180deg)' }}
          />
          <CaretCircleRightIcon
            onClick={() => swiperInstance?.slideNext()}
            width='35px'
            height='35px'
            fill='#000'
          />
        </div>
      </div>
    </div>
  );
};

export default ImportantStages;

import { Hamburger } from '../Hamburger/Hamburger';
import s from './MobileHeader.module.scss';
import pageBar from '../PageBar/PageBar.module.scss';
import { useNavigate } from 'react-router-dom';
import Logo from '../PageBar/logo.svg';
import { useCycle } from 'framer-motion';

const MobileHeader = () => {
  const navigate = useNavigate();
  const [isOpen, toggleOpen] = useCycle(false, true);
  return (
    <header
      style={{ flexDirection: 'row', flexWrap: 'nowrap' }}
      className={`${pageBar.bar} ${s.mobileHeader}`}
    >
      <Hamburger isOpen={isOpen} toggleOpen={toggleOpen} />
      <img
        className={s.logo}
        onClick={() => {
          if (isOpen) {
            toggleOpen();
          }
          navigate('/');
        }}
        src={Logo}
        alt='logo'
      />
    </header>
  );
};
export default MobileHeader;

import { motion } from 'framer-motion';
import { makeRepeated } from './array';

const DURATION = 1.5;
const PARAMETERS = {
  button: {
    flashCount: 3,
    flashInitialDelay: 1,
    flashDuration: 1.5,
    flashCooldown: 1.5,
  },
};

const DEFAULT_PROPS = (duration: number = DURATION, times?: Array<number>) => ({
  initial: 'hidden',
  whileInView: 'visible',
  viewport: { once: true, amount: 0.5 },
  transition: { duration, times },
});

const getButtonStages = () => {
  let stages = [];
  let lastStage = PARAMETERS.button.flashInitialDelay;

  for (let i = 0; i < PARAMETERS.button.flashCount * 3; ++i) {
    stages.push(lastStage);

    switch (i % 3) {
      case 0:
        lastStage += PARAMETERS.button.flashDuration / 2;
        break;

      case 1:
        lastStage += PARAMETERS.button.flashDuration / 2;
        break;

      case 2:
        lastStage += PARAMETERS.button.flashCooldown;
        break;
    }
  }

  return stages;
};

const getButtonDuration = (stages: Array<number>) => stages[stages.length - 1];

const getButtonTimes = (stages: Array<number>) =>
  stages.map((stage) => stage / getButtonDuration(stages));

const h1 = <T,>(props: T) => {
  return (
    <motion.h1
      {...props}
      {...DEFAULT_PROPS()}
      variants={{ hidden: { opacity: 0 }, visible: { opacity: 1 } }}
    />
  );
};

const h2 = <T,>(props: T) => {
  return (
    <motion.h2
      {...props}
      {...DEFAULT_PROPS()}
      variants={{ hidden: { y: 30 }, visible: { y: 0 } }}
    />
  );
};

const h3 = <T,>(props: T) => {
  return (
    <motion.h3
      {...props}
      {...DEFAULT_PROPS()}
      variants={{ hidden: { x: -30 }, visible: { x: 0 } }}
    />
  );
};

const h4 = <T,>(props: T) => {
  return (
    <motion.h4
      {...props}
      {...DEFAULT_PROPS()}
      variants={{ hidden: { x: 30 }, visible: { x: 0 } }}
    />
  );
};

const p = <T,>(props: T) => {
  return (
    <motion.p
      {...props}
      {...DEFAULT_PROPS()}
      variants={{ hidden: { opacity: 0 }, visible: { opacity: 1 } }}
    />
  );
};

const a = <T,>(props: T) => {
  return (
    <motion.a
      {...props}
      {...DEFAULT_PROPS()}
      variants={{ hidden: { opacity: 0 }, visible: { opacity: 1 } }}
    />
  );
};

const button = <T,>(props: T) => {
  const stages = getButtonStages();

  return (
    <motion.div
      {...props}
      {...DEFAULT_PROPS(getButtonDuration(stages), getButtonTimes(stages))}
      variants={{
        hidden: { filter: 'brightness(100%)' },
        visible: {
          filter: makeRepeated(
            ['brightness(100%)', 'brightness(115%)', 'brightness(100%)'],
            PARAMETERS.button.flashCount
          ),
        },
      }}
    />
  );
};

const img = <T,>(
  props: T & { custom?: number } & { reverse?: boolean } & { scale?: number }
) => {
  const custom = props.custom;
  const reverse = props.reverse;
  const scale = props.scale;
  return (
    <motion.img
      {...props}
      {...DEFAULT_PROPS()}
      variants={{
        hidden: {
          scale: scale ? scale : 1,
          x: custom ? (reverse ? custom * -1 : custom) : reverse ? -40 : 40,
        },
        visible: { x: 0, scale: 1 },
      }}
    />
  );
};

export const animation = {
  h1,
  h2,
  h3,
  h4,
  p,
  a,
  button,
  img,
};

import Image1 from './image-1.webp';
import Image2 from './image-2.webp';
import Image3 from './image-3.webp';
import { ReactComponent as Circle1 } from './circle-1.svg';
import { ReactComponent as Circle2 } from './circle-2.svg';
import { ReactComponent as Circle3 } from './circle-3.svg';
import s from './Statistics.module.scss';
import { animation } from '../../../../utils/animation';

const Statistics = () => {
  return (
    <div id='stats' className={s.wrapper}>
      <div className={s.container}>
        <animation.h2>Pan Tablet w liczbach</animation.h2>
        <div className={s.stats}>
          <div className={s.stat}>
            <div className={s.info}>
              <animation.h2>21 430</animation.h2>
              <animation.h3>uczniów uczęszcza każdego roku</animation.h3>
            </div>
            <div className={s.image}>
              <animation.img scale={1.2} src={Image1} alt='stat' />
              <Circle1 />
            </div>
          </div>
          <div className={s.stat}>
            <div className={s.info}>
              <animation.h2>1208</animation.h2>
              <animation.h3>
                nauczycieli rocznie korzysta z naszych usług
              </animation.h3>
            </div>
            <div className={s.image}>
              <animation.img scale={1.2} src={Image2} alt='stat' />
              <Circle2 />
            </div>
          </div>
          <div className={s.stat}>
            <div className={s.info}>
              <animation.h2>207</animation.h2>
              <animation.h3>szkół obsługujemy rocznie</animation.h3>
            </div>

            <div className={s.image}>
              <animation.img scale={1.2} src={Image3} alt='stat' />
              <Circle3 />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Statistics;

import Logo from '../PageBar/logo.svg';
import pageBar from '../PageBar/PageBar.module.scss';
import s from './Footer.module.scss';
import { animation } from '../../utils/animation';

const Footer = () => {
  return (
    <footer className={`${pageBar.bar} ${s.footer}`}>
      <div className={pageBar.left}>
        <animation.a href='/'>
          <img src={Logo} alt='logo' />
        </animation.a>
        <div className={`${pageBar.links} `}>
          <animation.a
            href='#'
            onClick={() =>
              window.open(
                'https://docs.google.com/document/d/1bjkIigL3sVR2xeQTsnPeSVQ3xipB8H1QcVCzZIcwpac',
                '_blank'
              )
            }
          >
            <p className='button-2 add-underline'>Regulamin</p>
          </animation.a>
          <animation.a
            href='#'
            onClick={() =>
              window.open(
                'https://docs.google.com/document/d/1dms5BuY3q7uuZhB2ICgpNMhH_Xfn3b98',
                '_blank'
              )
            }
          >
            <p className='button-2 add-underline'>Polityka Prywatności</p>
          </animation.a>
        </div>
      </div>

      <div className={`${pageBar.right} `}>
        <animation.a
          href='#'
          onClick={() => window.open('https://dchouse.pl/', '_blank')}
        >
          <p className='button-2 add-underline'>
            Realizacja: Dimensione Creativa 2023
          </p>
        </animation.a>
      </div>
    </footer>
  );
};

export default Footer;

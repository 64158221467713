import axios from 'axios';
import { useMutation } from '@tanstack/react-query';

export interface ISendEmailData {
  email: string;
  fullName: string;
  city: string;
  phoneNumber: string;
  schoolType: string;
}

export const useSendEmail = () => {
  return useMutation({
    mutationFn: (sendEmailData: ISendEmailData) =>
      axios.post('/consulting/send-email', {
        email: sendEmailData.email,
        name: sendEmailData.fullName,
        city: sendEmailData.city,
        phone: sendEmailData.phoneNumber,
        schooltype: sendEmailData.schoolType,
      }),
  });
};

import Button from '../../../../components/Button/Button';
import Globe from './globe.webp';
import button from '../../../../components/Button/Button.module.scss';
import s from './JoinToday.module.scss';
import { CaretCircleRightIcon } from '../../../../components/Icons/icons';
import { useNavigate } from 'react-router-dom';
import { animation } from '../../../../utils/animation';

const JoinToday = () => {
  const navigate = useNavigate();

  return (
    <div className={s.container}>
      <div className={s.image}>
        <animation.img reverse={true} src={Globe} alt='globe' />
      </div>

      <div className={s.offer}>
        <animation.h2>
          Zaufało nam już setki szkół. Dołącz do naszego grona już dziś!
        </animation.h2>

        <div className={s.schools}>
          <div className={s.school}>
            <div className={s.separator} />
            <animation.p className='body-2'>
              Szkoła Podstawowa nr 113 im. Adama Rapackiego we Wrocławiu
            </animation.p>
          </div>

          <div className={s.school}>
            <div className={s.separator} />
            <animation.p className='body-2'>
              Szkoła Podstawowa im. Wandy Chotomskiej w Kiełczowie
            </animation.p>
          </div>

          <div className={s.school}>
            <div className={s.separator} />
            <animation.p className='body-2'>
              Szkoła Podstawowa nr 50 we Wrocławiu
            </animation.p>
          </div>

          <div className={s.school}>
            <div className={s.separator} />
            <animation.p className='body-2'>
              Szkoła Podstawowa nr 22 we Wrocławiu
            </animation.p>
          </div>

          <div className={s.school}>
            <div className={s.separator} />
            <animation.p className='body-2'>
              Szkoła Podstawowa nr 44 im. Jana III Sobieskiego we Wrocławiu
            </animation.p>
          </div>

          <div className={s.school}>
            <div className={s.separator} />
            <animation.p className='body-2'>
              Szkoła Podstawowa nr 118 im. Płk Pilota Bolesława Orlińskiego we
              Wrocławiu
            </animation.p>
          </div>
        </div>

        <Button
          className={`${button.button} ${button.register} ${s.button}`}
          icon={<CaretCircleRightIcon />}
          onClick={() => navigate('/#packages')}
        >
          <p className='button-1'>Sprawdź naszą ofertę</p>
        </Button>
      </div>
    </div>
  );
};

export default JoinToday;

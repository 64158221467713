import Button from '../../../../components/Button/Button';
import Background from './background.webp';
import button from '../../../../components/Button/Button.module.scss';
import s from './ConsultUs.module.scss';
import { useNavigate } from 'react-router-dom';
import { animation } from '../../../../utils/animation';

const ConsultUs = () => {
  const navigate = useNavigate();

  return (
    <div id='contact' className={s.container}>
      <animation.img
        scale={0.8}
        reverse={true}
        className={s.background}
        src={Background}
        alt='background'
      />
      <div className={s.consult}>
        <animation.h2 className='headline-1a'>Gotowi do startu?</animation.h2>
        <animation.h4>
          Kliknij w przycisk i wypełnij prosty formularz kontaktowy. Nasi
          animatorzy skontaktują się z tobą jak najszybciej!
        </animation.h4>
        <Button
          onClick={() => navigate('/appointment')}
          className={`${button.button} ${button.register} ${s.button}`}
        >
          <p className='button-2'>Umów się z nami</p>
        </Button>
      </div>
    </div>
  );
};

export default ConsultUs;

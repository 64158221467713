import Marquee from 'react-fast-marquee';
import { useNavigate } from 'react-router-dom';
import { ArrowIcon } from '../../../../components/Icons/icons';
import s from './JoinMarquee.module.scss';

const JoinMarquee = () => {
  const navigate = useNavigate();
  return (
    <Marquee className={s.container} gradient={false}>
      {[...Array(4)].map((_, index) => (
        <div
          onClick={() => navigate('/appointment')}
          key={index}
          className={s.marqueeItem}
        >
          <p className='body-1 add-bold add-underline'>
            DOŁĄCZ DO NAS JUŻ TERAZ
          </p>
          <ArrowIcon />
        </div>
      ))}
    </Marquee>
  );
};

export default JoinMarquee;

import { animation } from '../../../../utils/animation';
import Child from './child.webp';
import s from './MissionCard.module.scss';

const MissionCard = () => {
  return (
    <div id='mission' className={s.card}>
      <div className={s.content}>
        <animation.h2>Stawiamy na rozwój dziecka</animation.h2>
        <animation.h3>
          45-minutowe Lekcje Pana Tableta dla przedszkoli i szkół realizują
          podstawę programową, rozszerzając tradycyjne zajęcia.
          <br />
          <br />
          Różnorodna tematyka, ciekawe, twórcze aplikacje oraz indywidualna
          praca na tablecie pozwalają rozwijać talenty dziecka i wnoszą radość z
          nauki.
        </animation.h3>
      </div>
      <div className={s.image}>
        <animation.img custom={40} src={Child} alt='child' />
      </div>
    </div>
  );
};

export default MissionCard;

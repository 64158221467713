import { motion } from 'framer-motion';
import { MenuItem } from '../MenuItem/MenuItem';
import Mission from '../../Header/MobilePictures/mission.webp';
import Statistics from '../../Header/MobilePictures/stats.webp';
import Classes from '../../Header/MobilePictures/classes.webp';
import Packages from '../../Header/MobilePictures/packages.webp';
import Platform from '../../Header/MobilePictures/platform.webp';
import Contact from '../../Header/MobilePictures/contact.webp';
import { useMemo } from 'react';

const variants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};

export const Navigation = () => {
  const categories = useMemo(
    () => [
      {
        img: Mission,
        desc: 'Misja',
        url: '/#mission',
      },
      {
        img: Statistics,
        desc: 'Statystyki',
        url: '/#stats',
      },
      {
        img: Classes,
        desc: 'Zajęcia',
        url: '/#classes',
      },
      {
        img: Packages,
        desc: 'Pakiety',
        url: '/#packages',
      },
      {
        img: Platform,
        desc: 'Platforma',
        url: '/#platform',
      },
      {
        img: Contact,
        desc: 'Kontakt',
        url: '/#contact',
      },
    ],
    []
  );
  return (
    <motion.ul variants={variants}>
      {categories.map((category: any, index: number) => (
        <MenuItem
          img={category.img}
          desc={category.desc}
          url={category.url}
          fn={category.fn}
          key={index}
        />
      ))}
    </motion.ul>
  );
};

import SubHeader from '../../components/SubHeader/SubHeader';
import MainBanner from './components/MainBanner/MainBanner';
import MissionCard from './components/MissionCard/MissionCard';
import Benefits from './components/Benefits/Benefits';
import JoinToday from './components/JoinToday/JoinToday';
import Statistics from './components/Statistics/Statistics';
import JoinMarquee from './components/JoinMarquee/JoinMarquee';
import ClassesCourse from './components/ClassesCourse/ClassesCourse';
import FeaturedVideo from './components/FeaturedVideo/FeaturedVideo';
import ImportantStages from './components/ImportantStages/ImportantStages';
import ExampleTopics from './components/ExampleTopics/ExampleTopics';
import Packages from './components/Packages/Packages';
import CheckPlatform from './components/CheckPlatform/CheckPlatform';
import ConsultUs from './components/ConsultUs/ConsultUs';
import globalColors from '../../styles/_colors.scss';
import { motion } from 'framer-motion';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { scrollToId } from '../../utils/scroll';
import { useMediaQuery } from 'usehooks-ts';

const IndexPage = () => {
  const { hash } = useLocation();
  const navigate = useNavigate();
  const overMaxWidth = !useMediaQuery('(max-width: 1512px)');

  useEffect(() => {
    if (hash.length > 1) {
      const section = hash.slice(1);
      scrollToId(section);

      setTimeout(() => {
        navigate('/');
      }, 100);
    }
  }, [hash, navigate]);

  return (
    <motion.div
      style={overMaxWidth ? {} : { overflowX: 'hidden' }}
      initial='hidden'
      animate='visible'
      exit={{ opacity: 0, transition: { duration: 1 } }}
    >
      <SubHeader color={globalColors.green1} />
      <MainBanner />
      <MissionCard />
      <Benefits />
      <JoinToday />
      <Statistics />
      <JoinMarquee />
      <ClassesCourse />
      <FeaturedVideo />
      <ImportantStages />
      <ExampleTopics />
      <Packages />
      <CheckPlatform />
      <ConsultUs />
    </motion.div>
  );
};

export default IndexPage;

import Topic1 from './images/topic-1.webp';
import Topic2 from './images/topic-2.webp';
import Topic3 from './images/topic-3.webp';
import Topic4 from './images/topic-4.webp';
import { motion, useCycle, useInView } from 'framer-motion';
import { useEffect, useRef } from 'react';
import s from './ExampleTopics.module.scss';
import { animation } from '../../../../utils/animation';

const ExampleTopics = () => {
  const topicOrders = [
    [0, 1, 2, 3],
    [2, 0, 3, 1],
    [3, 2, 1, 0],
    [1, 3, 0, 2],
  ];
  const [order, setOrder] = useCycle(...topicOrders);

  const ref = useRef(null);

  const isInView = useInView(ref);

  useEffect(() => {
    if (isInView) {
      setOrder();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInView]);

  const topics = [
    {
      img: Topic1,
      captionTitle: 'Ratujemy rezerwat przyrody',
      caption:
        'Rozwój wiedzy i kompetencji w zakresie rozumienia środowiska przyrodniczego ze szczególnym uwzględnieniem roli środowiska.',
    },
    {
      img: Topic2,
      captionTitle: 'Odkrywamy kreatywność',
      caption:
        'Rozwój kompetencji technologiczno-informatycznych w zakresie działań twórczych i realizacji własnych projektów.',
    },
    {
      img: Topic3,
      captionTitle: 'Budujemy miasto',
      caption:
        'Rozwój kompetencji technologiczno- informatycznych w zakresie projektowania.',
    },
    {
      img: Topic4,
      captionTitle: 'Świat muzyką malowany',
      caption:
        'Wyrażanie przeżyć i nastrojów związanych z treścią utworów muzycznych.',
    },
  ];

  return (
    <div className={s.container}>
      <animation.h2>Przykładowe tematy zajęć</animation.h2>
      <div ref={ref} className={s.topics}>
        {order.map((value) => (
          <motion.div
            layout
            transition={{ duration: 2 }}
            key={value}
            className={s.topic}
          >
            <img src={topics[value].img} alt='topic' />
            <div className={s.caption}>
              <animation.h4>
                <b>{topics[value].captionTitle}</b>
              </animation.h4>
              <animation.p className='body-3'>
                {topics[value].caption}
              </animation.p>
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default ExampleTopics;

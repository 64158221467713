import Button from '../../../../components/Button/Button';
import WorkEffect from './work-effect.webp';
import Platform from './platform.webp';
import { CaretCircleRightIcon } from '../../../../components/Icons/icons';
import button from '../../../../components/Button/Button.module.scss';
import s from './CheckPlatform.module.scss';
import { animation } from '../../../../utils/animation';

const CheckPlatform = () => {
  return (
    <div id='platform' className={s.container}>
      <animation.img
        scale={1.1}
        className={s.workEffectImage}
        src={WorkEffect}
        alt='work effects'
      />
      <animation.h2>
        Zobacz jak może wyglądać efekt pracy Twojego dziecka
      </animation.h2>
      <animation.img
        className={s.platformImage}
        src={Platform}
        alt='platform'
      />

      <Button
        className={`${button.button} ${button.login} ${s.button}`}
        icon={<CaretCircleRightIcon />}
        onClick={() =>
          window.open(process.env.REACT_APP_PLATFORM_URL, '_blank')
        }
      >
        <p className='button-2'>Sprawdź naszą platformę</p>
      </Button>
    </div>
  );
};

export default CheckPlatform;

import s from './FeaturedVideo.module.scss';
import YouTube from 'react-youtube';

const FeaturedVideo = () => {
  const opts = {
    playerVars: {
      origin: window.location.origin,
    },
  };
  return (
    <div className={s.container}>
      <div className={s.video}>
        <YouTube videoId='82Ne8j17As0' opts={opts} />
      </div>
    </div>
  );
};

export default FeaturedVideo;

import { Dispatch, SetStateAction, useCallback } from 'react';
import { CheckmarkSmallIcon } from '../../Icons/icons';
import s from './Checkbox.module.scss';

interface IProps {
  value: boolean;
  setValue?: Dispatch<SetStateAction<boolean>>;
  disabled?: boolean;
  label?: string;
}

const Checkbox = ({ value, setValue, disabled, label }: IProps) => {
  const onClick = useCallback(() => {
    if (setValue && !disabled) {
      setValue(!value);
    }
  }, [setValue, disabled, value]);

  return (
    <div className={s.container} onClick={onClick}>
      <div className={`${s.checkbox} ${value ? s.selected : ''}`}>
        {value && <CheckmarkSmallIcon />}
      </div>
      <p
        className={`${s.label} ${value ? 'button-2' : 'button-3'} ${
          value ? s.selected : ''
        }`}
      >
        {label}
      </p>
    </div>
  );
};

export default Checkbox;

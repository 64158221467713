import { ReactComponent as Circle1 } from './circles/circle-1.svg';
import { ReactComponent as Circle2 } from './circles/circle-2.svg';
import { ReactComponent as Circle3 } from './circles/circle-3.svg';
import Image1 from './images/side-image-1.webp';
import Image2 from './images/side-image-2.webp';
import Image3 from './images/side-image-3.webp';
import CustomizeImage from './customize.webp';
import ArrowImage from './arrow.webp';
import s from './Packages.module.scss';
import { ArrowIcon } from '../../../../components/Icons/icons';
import { useNavigate } from 'react-router-dom';
import { AnimatePresence, motion, useCycle } from 'framer-motion';
import { Fragment, useEffect, useState } from 'react';
import { animation } from '../../../../utils/animation';

const Packages = () => {
  const navigate = useNavigate();
  const imagesOrders = [
    [0, 1, 2],
    [2, 0, 1],
    [1, 2, 0],
  ];
  const [order, setOrder] = useCycle(...imagesOrders);
  const [exitImage, setExitImage] = useState(-1);
  const images = [Image1, Image2, Image3];

  useEffect(() => {
    const interval = setInterval(() => {
      setExitImage(order[order.length - 1]);
      setTimeout(() => {
        setExitImage(-1);
        setOrder();
      }, 300);
    }, 2000);

    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [setOrder]);

  return (
    <div id='packages' className={s.container}>
      <div className={s.header}>
        <animation.h2>Pakiety dopasowane do twoich potrzeb</animation.h2>
        <img width='122px' src={ArrowImage} alt='arrow' />
      </div>

      <div className={`${s.customize}`}>
        <animation.img
          reverse={true}
          width='200px'
          height='382px'
          src={CustomizeImage}
          alt='customize'
        />
        <div
          onClick={() => navigate('/appointment')}
          className={s.customizeText}
        >
          <animation.p className='button-1'>
            Dopasuj pakiet dla twojego dziecka
          </animation.p>
          <ArrowIcon />
        </div>
      </div>

      <div className={s.childrenImages}>
        <AnimatePresence>
          {order.map((value) =>
            exitImage === value ? (
              <Fragment key={null} />
            ) : (
              <motion.div
                layout
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0, y: 600, zIndex: 1 }}
                transition={{
                  opacity: { duration: 0.3 },
                  y: { duration: 1 },
                  layout: {
                    type: 'spring',
                    duration: 1,
                    bounce: 0.5,
                  },
                }}
                className={s.childImage}
                key={value}
              >
                <img src={images[value]} alt='' />
              </motion.div>
            )
          )}
        </AnimatePresence>
      </div>

      <div className={s.packages}>
        <div className={s.package}>
          <Circle1 />

          <div className={s.packageContent}>
            <div className={s.packageHeader}>
              <animation.h3>
                <b>Pakiet roczny</b>
              </animation.h3>

              <animation.p className='body-1'>13,00 zł/osoba</animation.p>
            </div>

            <animation.p className='body-3'>
              Materiały dydaktyczne dla nauczyciela. Przyjazd do szkoły.
              <br />
              Tablet dla każdego dziecka. 10 lekcji w pakiecie (jedna lekcja w
              miesiącu).
              <br />
              Księga z pracami dzieci po lekcji. <b>Oszczędzasz 24%</b>
            </animation.p>
          </div>
        </div>

        <div className={s.package}>
          <Circle2 />

          <div className={s.packageContent}>
            <div className={s.packageHeader}>
              <animation.h3>
                <b>Pakiet semestralny</b>
              </animation.h3>

              <animation.p className='body-1'>15,00 zł/osoba</animation.p>
            </div>

            <animation.p className='body-3'>
              Materiały dydaktyczne dla nauczyciela. Przyjazd do szkoły.
              <br />
              Tablet dla każdego dziecka. 5 lekcji w pakiecie (jedna lekcja w
              miesiącu).
              <br />
              Księga z pracami dzieci po lekcji. <b>Oszczędzasz 12%</b>
            </animation.p>
          </div>
        </div>

        <div className={s.package}>
          <Circle3 />

          <div className={s.packageContent}>
            <div className={s.packageHeader}>
              <animation.h3>
                <b>Pakiet jednorazowy</b>
              </animation.h3>

              <animation.p className='body-1'>17,00 zł/osoba</animation.p>
            </div>

            <animation.p className='body-3'>
              Pojedyncza lekcja. Materiały dydaktyczne dla nauczyciela.
              <br />
              Przyjazd do szkoły. Tablet dla każdego dziecka.
              <br />
              Księga z pracami dzieci po lekcji.
            </animation.p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Packages;

import { useEffect } from 'react';
import Button, { IProps as IButtonProps } from '../components/Button/Button';
import { UseMutationResult } from '@tanstack/react-query';

interface IProps {
  mutation: UseMutationResult<any, any, any, any>;
  mutationParam: any;
  onMutationError?: (error: any, reset: () => void) => void;
  onMutationSuccess?: (data: any) => void;
}

const ButtonQuery = (props: IButtonProps & IProps) => {
  const { onMutationError, onMutationSuccess } = props;
  const { isLoading, isError, error, isSuccess, data, mutate, reset } =
    props.mutation;

  useEffect(() => {
    if (isSuccess && onMutationSuccess) {
      onMutationSuccess(data);
    }
  }, [isSuccess, onMutationSuccess, data]);

  useEffect(() => {
    if (isError && onMutationError) {
      onMutationError(error, reset);
    }
  }, [isError, error, onMutationError, reset]);

  return (
    <Button
      {...props}
      disabled={isLoading ? true : props.disabled}
      onClick={(e) => {
        if (props.onClick) {
          props.onClick(e);
        }

        mutate(props.mutationParam);
      }}
    >
      {props.children}
    </Button>
  );
};

export default ButtonQuery;

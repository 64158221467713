import Banner from './banner.webp';
import MobileBanner from './banner_m.webp';
import { MouseIcon } from '../../../../components/Icons/icons';
import s from './MainBanner.module.scss';
import { useMediaQuery } from 'usehooks-ts';
import { animation } from '../../../../utils/animation';

export const MainBanner = () => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  return (
    <div className={s.banner}>
      <img
        className={s.bannerImage}
        src={isMobile ? MobileBanner : Banner}
        alt='banner'
      />
      <div className={s.bannerText}>
        <animation.h1>Odkrywamy talenty dzieci</animation.h1>
        <animation.h3>Lekcje Multimedialne z Panem Tabletem</animation.h3>
      </div>
      {isMobile ? null : <MouseIcon className={s.bannerIcon} />}
    </div>
  );
};

export default MainBanner;

import Benefit1 from './images/benefit-1.webp';
import Benefit2 from './images/benefit-2.webp';
import Benefit3 from './images/benefit-3.webp';
import Benefit4 from './images/benefit-4.webp';
import Benefit5 from './images/benefit-5.webp';
import Benefit6 from './images/benefit-6.webp';
import s from './Benefits.module.scss';
import { animation } from '../../../../utils/animation';

const Benefits = () => {
  return (
    <div className={s.container}>
      <animation.h2>Korzyści z zajęć</animation.h2>

      <div className={s.benefits}>
        <div className={`${s.benefit} ${s.regular}`}>
          <animation.img reverse={true} src={Benefit1} alt='benefit' />
          <animation.p className='body-1'>
            Nowoczesny sposób kształcenia
          </animation.p>
        </div>

        <div className={`${s.benefit} ${s.regular}`}>
          <animation.img src={Benefit2} alt='benefit' />
          <animation.p className='body-1'>
            Nauka w praktycznym działaniu
          </animation.p>
        </div>

        <div className={`${s.benefit} ${s.regular}`}>
          <animation.img reverse={true} src={Benefit3} alt='benefit' />
          <animation.p className='body-1'>
            Unikatowa struktura lekcji
          </animation.p>
        </div>

        <div className={`${s.benefit} ${s.big}`}>
          <animation.h4>
            Atrakcyjna dla dzieci forma edukacji.
            <br />
            Pobudzająca ciekawość i chęć do nauki.
          </animation.h4>
          <animation.img src={Benefit4} alt='benefit' />
        </div>

        <div className={`${s.benefit} ${s.regular}`}>
          <animation.img reverse={true} src={Benefit5} alt='benefit' />
          <animation.p className='body-1'>
            Dojeżdżamy do Twojej Szkoły
          </animation.p>
        </div>

        <div className={`${s.benefit} ${s.regular}`}>
          <animation.img src={Benefit6} alt='benefit' />
          <animation.p className='body-1'>
            Zapewniamy tablet dla każdego dziecka
          </animation.p>
        </div>
      </div>
    </div>
  );
};

export default Benefits;

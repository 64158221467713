import Logo from '../PageBar/logo.svg';
import {
  // InstagramIcon,
  YoutubeIcon,
  FacebookIcon,
} from '../Icons/icons';
import pageBar from '../PageBar/PageBar.module.scss';
import s from './Header.module.scss';
import { animation } from '../../utils/animation';

const Header = () => {
  return (
    <>
      <header className={`${pageBar.bar} ${s.header}`}>
        <div className={`${pageBar.left} ${s.left}`}>
          <animation.a href='/'>
            <img src={Logo} alt='logo' />
          </animation.a>
          <div className={`${pageBar.links} ${s.links}`}>
            <animation.a className='field-1 add-underline' href='/#mission'>
              Misja
            </animation.a>
            <animation.a className='field-1 add-underline' href='#stats'>
              Statystyki
            </animation.a>
            <animation.a className='field-1 add-underline' href='/#classes'>
              Zajęcia
            </animation.a>
            <animation.a className='field-1 add-underline' href='/#packages'>
              Pakiety
            </animation.a>
            <animation.a className='field-1 add-underline' href='/#platform'>
              Platforma
            </animation.a>
          </div>
        </div>
        <div className={pageBar.right}>
          <div className={s.socials}>
            {/* <InstagramIcon /> */}
            <YoutubeIcon
              onClick={() =>
                window.open(
                  'https://www.youtube.com/@pantablet4748/videos',
                  '_blank'
                )
              }
            />
            <FacebookIcon
              onClick={() =>
                window.open('https://pl-pl.facebook.com/PanTablet/', '_blank')
              }
            />
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;

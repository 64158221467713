import * as React from 'react';
import { Button as PrimeButton, ButtonProps } from 'primereact/button';
import { IconType } from 'primereact/utils';
import { animation } from '../../utils/animation';

export interface IProps
  extends Omit<
    React.DetailedHTMLProps<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >,
    'disabled' | 'ref'
  > {
  className?: string;
  disabled?: boolean;
  icon?: IconType<ButtonProps>;
  children?: React.ReactNode;
}

const Button = ({ className, disabled, icon, onClick, children }: IProps) => {
  return (
    <animation.button>
      <PrimeButton
        disabled={disabled}
        className={className}
        icon={icon}
        onClick={onClick}
      >
        {children}
      </PrimeButton>
    </animation.button>
  );
};

export default Button;

import Photo1 from './photos/photo-1.webp';
import Photo2 from './photos/photo-2.webp';
import Photo3 from './photos/photo-3.webp';
import Photo4 from './photos/photo-4.webp';
import Photo5 from './photos/photo-5.webp';
import Photo6 from './photos/photo-6.webp';
import Photo7 from './photos/photo-7.webp';
import s from './ClassesCourse.module.scss';
import { animation } from '../../../../utils/animation';

const ClassesCourse = () => {
  return (
    <div id='classes' className={s.container}>
      <animation.img
        reverse={true}
        src={Photo1}
        alt='Zajęcia z Panem Tabletem'
      />
      <animation.img src={Photo2} alt='Zajęcia z Panem Tabletem' />
      <animation.img
        reverse={true}
        src={Photo3}
        alt='Zajęcia z Panem Tabletem'
      />
      <animation.img src={Photo4} alt='Zajęcia z Panem Tabletem' />
      <animation.img
        reverse={true}
        src={Photo5}
        alt='Zajęcia z Panem Tabletem'
      />
      <animation.img src={Photo6} alt='Zajęcia z Panem Tabletem' />
      <animation.img
        reverse={true}
        src={Photo7}
        alt='Zajęcia z Panem Tabletem'
      />
      <div className={s.header}>
        <animation.h2 className='headline-1a'>
          Zobacz jak przebiegają nasze zajęcia
        </animation.h2>
      </div>
    </div>
  );
};

export default ClassesCourse;

import Input from '../../../../components/Input/Input';
import Checkbox from '../../../../components/Input/Checkbox/Checkbox';
import Appointment from './appointment.webp';
import {
  phoneNumberRegex,
  useValidableState,
  validateEmail,
} from '../../../../utils/validation';
import { useState } from 'react';
import { useSendEmail } from '../../../../api/consulting';
import ButtonQuery from '../../../../query/ButtonQuery';
import button from '../../../../components/Button/Button.module.scss';
import s from './AppointmentForm.module.scss';
import { animation } from '../../../../utils/animation';
import ContactFormPopup from '../../../../components/Popups/ContactFormPopup/ContactFormPopup';

enum SchoolType {
  None,
  Primary = 'Szkoła podstawowa',
  Kindergarten = 'Przedszkole',
  Other = 'Inne',
}

const AppointmentForm = () => {
  const [email, setEmail, isEmailValid] = useValidableState<string>(
    '',
    validateEmail
  );
  const [firstAndLastName, setFirstAndLastName, isFirstAndLastNameValid] =
    useValidableState('');
  const [city, setCity, isCityValid] = useValidableState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [schoolType, setSchoolType] = useState(SchoolType.None);

  const isFormValid =
    isEmailValid &&
    isFirstAndLastNameValid &&
    isCityValid &&
    schoolType !== SchoolType.None;

  const sendEmail = useSendEmail();

  const [popupVisible, setPopupVisible] = useState(false);

  const clearForm = () => {
    setSchoolType(SchoolType.None);
    setEmail('');
    setCity('');
    setFirstAndLastName('');
    setPhoneNumber('');
  };

  return (
    <>
      <ContactFormPopup
        otherIncentive='Dziękujemy za przesłanie formularza. Skontaktujemy się z Tobą najszybciej jak się da :)'
        visible={popupVisible}
        setVisible={setPopupVisible}
      />
      <div className={s.container}>
        <div className={s.info}>
          <animation.h2>
            Rozwijaj kreatywność i wiedzę swojego dziecka
          </animation.h2>
          <animation.h4>
            Wypełnij prosty formularz kontaktowy, dzięki któremu będzie mogli
            się z Tobą umówić i ustalić termin próbnej lekcji.
          </animation.h4>

          <animation.img
            width='446px'
            height='444px'
            scale={0.8}
            src={Appointment}
            alt='appointment'
          />
        </div>

        <div className={s.appointment}>
          <div className={s.inputs}>
            <Input
              value={email}
              setValue={setEmail}
              placeholder='Adres email*'
              keyfilter='email'
            />

            <Input
              value={firstAndLastName}
              setValue={setFirstAndLastName}
              placeholder='Imię i nazwisko*'
            />

            <Input value={city} setValue={setCity} placeholder='Miasto*' />

            <Input
              value={phoneNumber}
              setValue={setPhoneNumber}
              placeholder='Nr telefonu'
              keyfilter={phoneNumberRegex}
            />
          </div>

          <animation.p className='field-1'>Rodzaj Twojej placówki?</animation.p>
          <div className={s.checkboxes}>
            <Checkbox
              value={schoolType === SchoolType.Primary}
              setValue={() => setSchoolType(SchoolType.Primary)}
              label={SchoolType.Primary.valueOf()}
            />

            <Checkbox
              value={schoolType === SchoolType.Kindergarten}
              setValue={() => setSchoolType(SchoolType.Kindergarten)}
              label={SchoolType.Kindergarten.valueOf()}
            />

            <Checkbox
              value={schoolType === SchoolType.Other}
              setValue={() => setSchoolType(SchoolType.Other)}
              label={SchoolType.Other.valueOf()}
            />
          </div>

          <animation.p className='field-2'>
            Klikając “Wyślij zgłoszenie”, akceptujesz{' '}
            <animation.a
              onClick={() =>
                window.open(
                  'https://docs.google.com/document/d/1dms5BuY3q7uuZhB2ICgpNMhH_Xfn3b98',
                  '_blank'
                )
              }
              href='#'
            >
              <b className='add-underline'>Politykę Prywatności</b>
            </animation.a>{' '}
            oraz{' '}
            <animation.a
              onClick={() =>
                window.open(
                  'https://docs.google.com/document/d/1bjkIigL3sVR2xeQTsnPeSVQ3xipB8H1QcVCzZIcwpac',
                  '_blank'
                )
              }
              href='#'
            >
              <b className='add-underline'>Regulamin</b>
            </animation.a>
            .
          </animation.p>
          <ButtonQuery
            onClick={() => {
              clearForm();
              setPopupVisible(true);
            }}
            disabled={!isFormValid}
            className={`${button.button} ${button.login} ${s.button}`}
            mutation={sendEmail}
            mutationParam={{
              email,
              fullName: firstAndLastName,
              city,
              phoneNumber,
              schoolType: schoolType.valueOf(),
            }}
          >
            <p className='button-5'>Wyślij zgłoszenie</p>
          </ButtonQuery>
        </div>
      </div>
    </>
  );
};

export default AppointmentForm;

import 'primereact/resources/primereact.min.css';
import './App.scss';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import IndexPage from './pages/IndexPage';
import AppointmentPage from './pages/AppointmentPage';
import { useLocation, useRoutes } from 'react-router-dom';
import { useScrollbarWidthCSS } from './utils/scroll';
import { AnimatePresence } from 'framer-motion';
import { cloneElement } from 'react';
import MobileHeader from './components/Header/MobileHeader';
import { useMediaQuery } from 'usehooks-ts';
import MrTabletCookieConsent from './components/CookieConsent/CookieConsent';

const App = () => {
  useScrollbarWidthCSS();
  const mobile = useMediaQuery('(max-width: 768px)');

  const element = useRoutes([
    {
      path: '/',
      element: <IndexPage />,
    },
    {
      path: '/appointment',
      element: <AppointmentPage />,
    },
  ]);

  const location = useLocation();

  if (!element) return null;

  const header = mobile ? <MobileHeader /> : <Header />;

  return (
    <AnimatePresence mode='sync'>
      {cloneElement(header, { key: location.pathname + 'header' })}
      {cloneElement(element, { key: location.pathname })}
      {cloneElement(<Footer />, { key: location.pathname + 'footer' })}
      <MrTabletCookieConsent />
    </AnimatePresence>
  );
};

export default App;

import AppointmentForm from './components/AppointmentForm/AppointmentForm';
import { motion } from 'framer-motion';

const AppointmentPage = () => {
  return (
    <motion.div
      initial='hidden'
      animate='visible'
      exit={{ opacity: 0, transition: { duration: 1 } }}
    >
      <AppointmentForm />
    </motion.div>
  );
};

export default AppointmentPage;

import Image from './img.webp';
import { IBasePopupProps, withPopup } from '../withPopup';
import s from './ContactFormPopup.module.scss';
import button from '../../Button/Button.module.scss';
import Button from '../../Button/Button';

interface IProps {
  otherIncentive?: string;
}

const ContactFormPopup = ({
  close,
  otherIncentive,
}: IBasePopupProps & IProps) => {
  let standardIncentive = '';
  return (
    <div className={s.container}>
      <img width='294px' src={Image} alt='' />
      <h3 className='headline-3b'>Dziękujemy za skorzystanie z formularza</h3>
      <p className='body-3'>
        {otherIncentive ? otherIncentive : standardIncentive}
      </p>

      <div className={s.buttons}>
        <Button
          onClick={() => {
            if (close) {
              close();
            }
          }}
          className={`${button.button} ${button.login}`}
        >
          <p className='button-5'>OK, dziękuję</p>
        </Button>
      </div>
    </div>
  );
};

export default withPopup(ContactFormPopup);
